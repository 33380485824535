import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { keyframes } from '@emotion/react';
import bgVideo from './bg.mp4';  // Import your video

// Keyframes for the moving linear gradient across the specified colors
const gradientChange = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const HeroSection = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);

  // Function to handle screen resizing
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    // Add event listener for resizing
    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Box
      sx={{
        height: '100vh',
        backgroundColor: 'black',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {/* Background video */}
      <video
        autoPlay
        muted
        loop
        playsInline
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          zIndex: 1, // Behind the content
          filter: 'brightness(0.9)', // Optional: darkens the video for better text readability
        }}
      >
        <source src={bgVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Main Heading */}
      <Typography
        variant="h2"
        sx={{
          color: 'white',
          width: {
            xs: '90%', // 90% width for mobile screens
            sm: '70%', // 70% for small screens
            md: '70%', 
            lg:'60%',// 60% for medium screens
          },
          fontWeight: '800', // Updated font-weight
          fontFamily: 'Poppins, sans-serif', // Updated font-family
          textAlign: 'center',
          position: 'relative',
          // textShadow: '1px 1px 2px white',
          zIndex: 2, // Above the video
          fontSize: {
            xs: '32px', // Smaller font size for mobile screens
            sm: '48px', // Medium size for small screens
            md: '71px', // Larger size for medium and above
          },
          '& span': {
            background: 'linear-gradient(270deg, #D4145A, #FBB03B, #009245, #FCEE21, #662D8C, #ED1E79, #FF512F, #DD2476, #EA8D8D, #A890FE, #FF61D2, #FE9090)',
            backgroundSize: '1000% 1000%',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            animation: `${gradientChange} 20s ease infinite`,
            textShadow: 'none', // Slow gradient animation
          },
          marginTop: {
            xs: '0%', // Increase margin for mobile
            sm: '15%',
            md: '1%',
          },
        }}
      >
        Turning light bulb <span>moments</span> into 
        big time moves.
      </Typography>

      {/* Subheading */}
      <Typography
        variant="body1"
        sx={{
          color: 'grey',
          mt: 2,
          width: {
            xs: '90%', // 90% width for mobile screens
            sm: '70%', // 70% for small screens
            md: '40%', // 60% for medium screens
          },
          textAlign: 'center',
          zIndex: 2, // Above the video
          fontFamily: 'Khula, sans-serif', // Updated font-family
          fontWeight: '400', // Updated font-weight
          fontSize: {
            xs: '14px', // Smaller size for mobile
            sm: '16px', // Medium size for small screens
            md: '18px', // Larger size for medium and above
          },
          marginTop: {
            xs: '3%', // Increase margin for mobile
            sm: '5%',
            md: '2.5%',
          },
        }}
      >
        From initial spark to market impact, we transform breakthrough ideas into market-ready innovations. Let's bring your next big idea to life.

      </Typography>

      {/* Lottie Animation
      <div style={{ zIndex: 2 }} className='lottiePlayer'
        dangerouslySetInnerHTML={{
          __html: 
            `<dotlottie-player 
              src="https://lottie.host/c92f8182-f231-422d-a623-8f4dd3dacf45/DI1PcVbi2O.json"
              background="transparent"
              speed="1"
              style="width: ${isMobile ? '350px' : '900px'}; height: ${isMobile ? '400px' : '1000px'}; position: absolute; top: ${isMobile ? '85%' : '70%'}; left: 50%; transform: translate(-50%, -50%); z-index: 2;"
              direction="1"
              playMode="normal"
              loop
              autoplay>
            </dotlottie-player>`
        }}
      /> */}
    </Box>
  );
};

export default HeroSection;

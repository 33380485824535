import React, { useState, useRef, useEffect } from "react";
import { AppBar, Box, Button, Toolbar, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/system";
import logo from './images/logo1.png'; // Correct import for the logo image

// Styled Container with animation and responsive width
const Container = styled('div')(({ theme, isActive }) => ({
  position: 'fixed',
  top: '0px',
  right: '0px',
  width: '30%',
  height: 'auto',
  zIndex: 8,
  backgroundColor: '#ffffff',
  color: 'black',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.5)',
  transform: isActive ? 'translate(0, 0)' : 'translate(100%, -100%)', // Slide in from top-right
  transition: 'transform 0.5s ease, opacity 0.5s ease', // Smooth transition
  opacity: isActive ? 1 : 0, // Fade in and out
  pointerEvents: isActive ? 'auto' : 'none', // Only interactable when visible

  // Responsive design for different screen sizes
  [theme.breakpoints.down('lg')]: {
    width: '40%', // Full width on extra small devices
  },
  [theme.breakpoints.down('md')]: {
    width: '50%', // Adjust width for tablets and medium screens
  },
  [theme.breakpoints.down('sm')]: {
    width: '100%', // Adjust width for mobile devices
  },
  [theme.breakpoints.down('xs')]: {
    width: '100%', // Full width on extra small devices
  },
}));

const CloseButton = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: '10px',
  width: '30px',
  height: '30px',
  cursor: 'pointer',
  '&::before, &::after': {
    content: '""',
    position: 'absolute',
    width: '100%',
    height: '3px', // Thin lines
    backgroundColor: 'black',
    top: '50%',
    left: '0',
  },
  '&::before': {
    transform: 'rotate(45deg)',
  },
  '&::after': {
    transform: 'rotate(-45deg)',
  },
}));

const NavBar = () => {
  const [isActive, setIsActive] = useState(false);
  const containerRef = useRef(null);

  const handleContactClick = () => {
    setIsActive(!isActive);
  };

  // Close the container when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        setIsActive(false); // Close container when clicking outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    
    return () => {
      document.removeEventListener("mousedown", handleClickOutside); // Cleanup listener on unmount
    };
  }, []);

  return (
    <>
      {/* Navbar */}
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: "rgba(255, 255, 255, 0.1)", // Semi-transparent background
          backdropFilter: "blur(3px)", // Background blur effect
          boxShadow: "none",
          zIndex: 7,
        }}
      >
        <Toolbar sx={{ justifyContent: "space-between", px: { xs: 2, sm: 3, md: 4 } }}>
          {/* Logo */}
          <Box sx={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
            <IconButton edge="start">
              <img src={logo} alt="Logo" style={{ height: "50px" }} /> 
            </IconButton>
            <Typography
              variant="h6"
              sx={{display: { xs: 'none', sm: 'none', md: 'block' }}}>
                  snippetScript
            </Typography>
          </Box>
          <Box>
            <Button
              onClick={handleContactClick}
              sx={{
                color: "#fff",
                border: "1px solid #fff",
                borderRadius: "20px",
                px: { xs: 2, sm: 3 },
                py: { xs: 0.5, sm: 1 }, // Adjust padding for smaller screens
              }}
            >
              Contact Us
            </Button>
          </Box>
        </Toolbar>
      </AppBar>

      <Container isActive={isActive} ref={containerRef}>
        {isActive && (
          <>
            {/* Close button and logo in one line */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center", // Align items vertically centered
                p: { xs: "2px 2px", sm: "2px 2px",md:"2px 2px" }, // Responsive padding
              }}
            >
              <IconButton edge="start">
                <img src={logo} alt="Logo" style={{ height: "50px" }} /> {/* Using the imported logo */}
              </IconButton>
              <CloseButton onClick={handleContactClick} />
            </Box>

            {/* Navigation Links */}
            <Box
              sx={{
                mt: 2,
                color: "black",
                width: { xs: "90%", sm: "80%", md: "80%" }, // Responsive width for navigation links
                mx: "auto", 
                textAlign: "left", 
              }}
            >
              <Typography
                variant="h6"
                sx={{ cursor: "pointer", mb: 2,ml:{ xs: 0, sm: -3,md:-3.5, lg: -3.5 },'& span': {color: "black","&:hover": { color: "#ff3fd5" },} }}
              >
                Got dreams? Let's build them <span onClick={() => window.open('mailto:hello@protify.com', '_blank')}>buildit@snippetscript.com</span>
              </Typography>
            </Box>

            {/* Contact Info */}
            <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  justifyContent: "space-between",
                  mt: 3,
                  px: { xs: 2, sm: 3 },
                  textAlign: "left",
                  ml:{ xs: 0, sm: -2, md: -1.3 },
                  mb: 2
                }}
              >
                <Box sx={{ mb: { xs: 2, sm: 0 } }}>
                  <Typography 
                    variant="h6" 
                    sx={{ "&:hover": { color: "#ff3fd5" }, cursor: "pointer" }}
                    onClick={() => window.location.href = 'tel:+918838732479'}
                  >
                    +918838732479
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                  <Typography
                    variant="h6"
                    sx={{ "&:hover": { color: "#ff3fd5" }, cursor: "pointer", marginRight: "5%" }}
                    onClick={() => window.open('https://wa.me/+918838732479', '_blank')}
                  >
                    Whatsapp
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{ "&:hover": { color: "#ff3fd5" }, cursor: "pointer" }}
                    onClick={() => window.open('https://www.instagram.com/snippet.script?igsh=Nm0xOGVpN3V3MnFl', '_blank')}
                  >
                    Instagram
                  </Typography>
                </Box>
              </Box>
          </>
        )}
      </Container>
    </>
  );
};

export default NavBar;

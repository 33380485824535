import { Typography, Box, Divider } from '@mui/material';
import React, { useEffect } from 'react';
import './Footer.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Footer = () => {
  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
  }, []);

  return (
    <div data-aos="fade-up" >
      {/* Set overflow to hidden to prevent horizontal scrolling */}
      <Box sx={{ width: '90%',margin:'22% auto 10% 10%', }} >
        <Typography
          sx={{
            fontSize: {lg:'50px',md:'50px',sm:'50px',xs:'38px'},
            fontFamily: 'Poppins',
            fontWeight: 500,
            marginBottom: '5%',
            color: { lg: 'white', md: 'white', sm: 'white', xs: 'white' },
            cursor: 'default',
            '& span': {
              fontWeight: 650,
              transition: 'color 0.3s ease',
              
                color: { lg: '#ff3fd5', md: '#ff3fd5', sm: '#ff3fd5', xs: '#ff3fd5' }
              
            },
          }}
        >
          LET'S <span>CONNECT</span>
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', height: '100%', width: '100%' }}>
          <Divider 
            sx={{ 
              borderBottomWidth: 1,  
              width: {lg:'83%',md:'83%',sm:'83%',xs:'93%'},         
              backgroundColor: 'rgb(39, 39, 39)', 
            }} 
          />
          
          {/* <Button 
            variant="contained"
            sx={{
              cursor: 'pointer',
              position: 'relative',
              backgroundColor: 'white',
              color: 'black',           
              borderRadius: { lg: '50%', md: '50%', sm: '50%', xs: '0%' },     
              width: { lg: '10%', md: '15%', sm: '18%', xs: '0%' },
              height: { lg: '35%', md: '40%', sm: '10%', xs: '0%' },         
              padding: { lg: '4%', md: '6%', sm: '7%', xs: '0%' },              
              fontSize: '16px',       
              textAlign: 'center',     
              display: 'flex',        
              alignItems: 'center',    
              justifyContent: 'center',
              zIndex: 1,
              overflow: 'hidden',
              transition: 'box-shadow 300ms ease-in-out',
              '&::before': {
                content: '""',
                borderRadius: { lg: '50%', md: '50%', sm: '50%', xs: '0%' },      
                zIndex: '-1',
                position: 'absolute',
                top: '0',
                left: '0',
                right: '0',
                bottom: '0',
                backgroundColor: '#ff3fd5',
                transform: 'translateY(100%)', 
                transition: 'transform 300ms ease-in-out', 
              },
              '&:hover::before': {
                transform: 'translateY(0%)',
              },
              '&:hover': {
                boxShadow: '0 0 15px rgba(255, 63, 213)', 
                color: 'white',
              }
            }}
          >
            Connect
          </Button> */}
        </Box>

        <Box sx={{ display: {lg:'flex',md:'flex',sm:'flex',xs:'block'}, width: {lg:'90%',md:'83%', sm:'90%',xs:'80%'}, justifyContent: 'space-between', marginBottom: {lg:'10%',md:'10%',sm:'10%',xs:'30%'}, marginTop:'6%', alignItems:'center' }}>
          <Box sx={{ display: 'block', width: {lg:'45%',md:'45%', sm:'45%'}, color: '#ada9a0', marginTop:{lg:'0%', sm:'4%'}}}>
            <Typography>We collaborate with ambitious brands and people worldwide.</Typography>
            <Typography sx={{marginTop:'5%'}}>Get in touch to find out more about digital experiences to effectively reach and engage customers and target audiences.</Typography>
          </Box>
          <Box sx={{ display: 'block', width: {lg:'45%',md:'45%', sm:'45%'}, color: '#ada9a0',  marginTop:{lg:'0%',md:'0%',sm:'4%',xs:'8%'},   }}>
            <Typography component="a" href="mailto:buildit@snippetscript.com" sx={{'&:hover': { color: { lg: '#ff3fd5', md: '#ff3fd5', sm: 'none', xs: 'none' }, cursor: 'pointer', }, textDecoration: 'none',color: '#ada9a0' }}>buildit@snippetscript.com</Typography>
            <Typography sx={{marginTop:'4%'}}>snippetScript office at Aalamarm Incubator, navaindia, coimbatore - 641004</Typography>
            <Typography component="a" href="tel:+918838732479" sx={{'&:hover': { color: {lg:'#ff3fd5', md:'#ff3fd5', sm:'none', xs:'none'}}, color:'#ada9a0', cursor: 'pointer', marginTop:{lg:'0%', sm:'4%', xs:'8%'}, textDecoration: 'none'}}>+91 8838732479</Typography>

          </Box>
        </Box>

        {/* <Box sx={{ display: 'flex', width: {lg:'70%',md:'73%', sm:'80%'}, justifyContent: 'space-between', marginBottom: '3%' }} data-aos="fade-up">
          <Box sx={{ display: 'flex', width: {lg:'30%',md:'40%', sm:'40%'}, color: '#ada9a0', justifyContent: 'space-between' }}>
            <Box><Typography sx={{ '&:hover': {color: {lg:'#ff3fd5', md:'#ff3fd5', sm:'none', xs:'none'}, }, cursor: 'pointer' }}>Home</Typography></Box>
            <Box><Typography sx={{ '&:hover': { color: {lg:'#ff3fd5', md:'#ff3fd5', sm:'none', xs:'none'}, }, cursor: 'pointer' }}>About</Typography></Box>
            <Box><Typography sx={{ '&:hover': { color: {lg:'#ff3fd5', md:'#ff3fd5', sm:'none', xs:'none'}, }, cursor: 'pointer' }}>Project</Typography></Box>
            <Box><Typography sx={{ '&:hover': { color: {lg:'#ff3fd5', md:'#ff3fd5', sm:'none', xs:'none'}, }, cursor: 'pointer' }}>Articles</Typography></Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: "flex-end", cursor: "pointer", width: {lg:'40%',md:'60%', sm:'50%'} }}>
            <Box><Typography sx={{ color: "#ada9a0" }}>Design by </Typography></Box>
            <Box><Typography sx={{ '&:hover': { color: {lg:'#ff3fd5', md:'#ff3fd5', sm:'none', xs:'none'}, }, paddingLeft: "4px", color: 'white' }}>Snippet</Typography></Box> 
          </Box>
        </Box> */}
      </Box>
      <Box sx={{ width:{lg:'50%',md:'80%',sm:'70%',xs:'90%'}, alignItems:'center', display:'flex', justifyContent:'center', margin:{lg:'-4% auto 0% auto',md:'0 auto 0 auto',sm:'0 auto 5% auto',xs:'-20% auto 5% auto'}, minHeight: '100px' }}>
        <Typography sx={{color:'#ada9a0', textAlign:'center',lineHeight:'25px',fontSize:{lg:'16px',md:'16px',sm:'16px',xs:'11px'}}}>யாமறிந்த மொழிகளிலே தமிழ்மொழிபோல் இனிதாவ தெங்குங் காணோம்</Typography>
      </Box>
      {/* <Box sx={{ width:{lg:'30%',md:'40%',sm:'50%',xs:'100%'}, alignItems:'center', display:'flex', justifyContent:'center', margin:{lg:'-4.5% auto 0% auto',md:'0 auto 0 auto',sm:'0 auto 5% auto',xs:'0 auto 5% auto'}, minHeight: '100px' }}>
        <Typography sx={{color:'#ada9a0', textAlign:'center',lineHeight:'25px',fontSize:{lg:'10px',md:'10px',sm:'10px',xs:'10px'}}}>© 2024 — snippetScript</Typography>
      </Box> */}

    </div>
  );
};

export default Footer;

import React, { useRef, useEffect, useState } from 'react';
import '../Services/Services.css';
import { motion, useScroll, useTransform } from 'framer-motion';

const Services = () => {
  const [isHoveredCard1, setIsHoveredCard1] = useState(false);
  const [isHoveredCard2, setIsHoveredCard2] = useState(false);
  const [isHoveredCard3, setIsHoveredCard3] = useState(false);

  // Handle screen resizing
  const targetRef = useRef(null);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const { scrollYProgress } = useScroll({ target: targetRef });
  const x = useTransform(scrollYProgress, [0, 0.4, 1], window.innerWidth < 1200 ? ["40%", "0%", "-57%"] : ["40%", "0%", "-55%"]);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      setIsSmallScreen(screenWidth < 900); // Define "small" as screens below 900px width
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='carousel' ref={targetRef}>
      <div className='contentContainer'>
        {isSmallScreen && <h2 className='servicesTitle'>Our <span className='servicesSpan'>Services</span></h2>}

        <motion.div className='images' style={{ x: isSmallScreen ? "0%" : x }}>

          {/* Card 1 */}
          <div className='Card' id='Card1'
            onMouseEnter={!isSmallScreen ? () => setIsHoveredCard1(true) : null}
            onMouseLeave={!isSmallScreen ? () => setIsHoveredCard1(false) : null}>
            <div className='CardHead'>Product Development</div>
            <div className='CardText'>We transform raw ideas into market-ready champions. From concept validation to launch day glory, we build what makes users say WOW</div>
            <div className='lottieContainer'>
              {/* {!isHoveredCard1 && !isSmallScreen ? (
                <img src={webDevelop} alt="App Development" className='lottiePlayerImg1' />
              ) : ( */}
                <div
                  className='lottiePlayer1'
                  dangerouslySetInnerHTML={{
                    __html: `
                      <dotlottie-player 
                        src="https://lottie.host/56be653a-d25a-4113-b0d8-3c8cc9a8c222/CrzsYdgDqA.json"
                        background="transparent"
                        speed="1"
                        direction="1"
                        playMode="normal"
                        loop
                        autoplay>
                      </dotlottie-player>
                    `
                  }}
                />
              {/* )} */}
            </div>
          </div>

          {/* Card 2 */}
          <div className='Card' id='Card2'
            onMouseEnter={!isSmallScreen ? () => setIsHoveredCard2(true) : null}
            onMouseLeave={!isSmallScreen ? () => setIsHoveredCard2(false) : null}>
            <div className='CardHead'>Product Design</div>
            <div className='CardText'>
              Where form meets function and beauty means business. We craft interfaces and experiences that users can't help but LOVE
            </div>
            <div className='lottieContainer'>
              {/* {!isHoveredCard2 && !isSmallScreen ? (
                <img src={webDesignStatic} alt="Web Design" className='lottiePlayerImg2' />
              ) : ( */}
                <div
                  className='lottiePlayer2'
                  dangerouslySetInnerHTML={{
                    __html: `
                      <dotlottie-player 
                        src="https://lottie.host/b8d4bd48-132d-4d92-b1fc-243760925f80/SAzpdKFur7.json"
                        background="transparent"
                        speed="1"
                        style="width: 300px; height: 300px;"
                        direction="1"
                        playMode="normal"
                        loop
                        autoplay>
                      </dotlottie-player>
                    `
                  }}
                />
              {/* )} */}
            </div>
          </div>

          {/* Card 3 */}
          <div className='Card' id='Card3'
            onMouseEnter={!isSmallScreen ? () => setIsHoveredCard3(true) : null}
            onMouseLeave={!isSmallScreen ? () => setIsHoveredCard3(false) : null}>
            <div className='CardHead'>Consultation</div>
            <div className='CardText'>
              Strategic wisdom meets practical know-how. We guide your product decisions with battle-tested insights and zero-fluff direction
            </div>
            <div className='lottieContainer'>
              {/* {!isHoveredCard3 && !isSmallScreen ? (
                <img src={appDevop} alt="Brand Strategy" className='lottiePlayerImg3' />
              ) : ( */}
                <div
                  className='lottiePlayer3'
                  dangerouslySetInnerHTML={{
                    __html: `
                      <dotlottie-player 
                        src="https://lottie.host/054d19db-d7db-4060-a8c9-379744af3012/v9l1O2Ikdy.json"
                        background="transparent"
                        speed="1"
                        
                        direction="1"
                        playMode="normal"
                        loop
                        autoplay>
                      </dotlottie-player>
                    `
                  }}
                />
              {/* )} */}
            </div>
          </div>

        </motion.div>
      </div>
    </div>
  );
};

export default Services;

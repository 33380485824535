import React, { useEffect, useState } from 'react';
import '../ScrollAnimation/ScrollAni.css';
import Marquee from "react-fast-marquee";
import AOS from 'aos';
import 'aos/dist/aos.css';

const ScrollAni = () => {
  const [speed, setSpeed] = useState(100); 

  useEffect(() => {
    AOS.init({ duration: 1000, once: false });

    const updateSpeed = () => {
      const width = window.innerWidth;

      if (width <= 576) {
        setSpeed(90); 
      } else if (width <= 768) {
        setSpeed(60); 
      } else if (width <= 992) {
        setSpeed(80); 
      } else {
        setSpeed(100); 
      }
    };
    updateSpeed();

    window.addEventListener('resize', updateSpeed);

    return () => {
      window.removeEventListener('resize', updateSpeed);
    };
  }, []);

  return (
    <div className='scroll_container' data-aos="zoom-in">
      <Marquee direction='left' speed={speed}>
        E / MAKE / CLASSIC / PRODUCTS / FOR / YOUR / BUSINESS / ENTERPRISES / W
      </Marquee>
      <Marquee direction='right' className='marqueeRight' speed={speed}>
        E / MAKE / CLASSIC / PRODUCTS / FOR / YOUR / BUSINESS / ENTERPRISES / W
      </Marquee>
    </div>
  );
}

export default ScrollAni;

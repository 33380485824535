import React, { useState } from 'react';

import LoadingAnimation from './comp/LoadingAnimation/LoadingAnimation';
import ResponsiveAppBar from './comp/navbar/ResponsiveAppBar'
// import Home from './comp/navbar/Home';
// import Header from './comp/Header/Header';
import HeroSection from './comp/HeroSection/HeroSection';
import TextReveal from './comp/TextReveal/TextReveal';
import '../src/App.css';
import ScrollAni from './comp/ScrollAnimation/ScrollAni';
import FAQ from './comp/FAQ/FAQ';
// import WhyWorkWithMe from './comp/WhyWorkWithMe/WhyWorkWithMe';
import Services from './comp/Services/Services';
import WhyWorkWithMe from './comp/WhyWorkWithMe/WhyWorkWithMe';
import Footer from './comp/Footer/Footer';


function App() {
  // State to track if loading is complete
  const [loadingComplete, setLoadingComplete] = useState(false);

  return (
    <div>
      {/* Render LoadingAnimation first */}
      {!loadingComplete && (
        <LoadingAnimation duration={1000} onLoadingComplete={() => setLoadingComplete(true)} />
      )}

      {/* Render the rest of the components only after loading is complete */}
      {loadingComplete && (
        <>
           {/* <Home />  */}
          {/* <Header /> */}
          <ResponsiveAppBar />
          <HeroSection />
          <TextReveal />
          <ScrollAni />
          
          <Services />
          <WhyWorkWithMe />
          <FAQ />
          <Footer />
        </>
      )}
    </div>
  );
}

export default App;

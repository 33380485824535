import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Box, Typography } from "@mui/material";
import { Player } from '@lottiefiles/react-lottie-player'; // Import the Lottie Player
import loading_ani from '../images/loading_animation.webp'; // Import the background image
import AOS from "aos"; // Import AOS
import "aos/dist/aos.css"; // Import AOS styles
import '../LoadingAnimation/LoadingAnimation.css'

export default function LoadingAnimation({ duration = 10000, onLoadingComplete }) {
  const [count, setCount] = useState(0);
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [helloBroComplete, setHelloBroComplete] = useState(false); // Track "Hello Bro" animation
  const [howAreYouComplete, setHowAreYouComplete] = useState(false); // Track "How are you" animation
  const [showLottie, setShowLottie] = useState(false); // Control Lottie animation
  const [currentTextIndex, setCurrentTextIndex] = useState(-1); // Track current text index
  const [fadeOut, setFadeOut] = useState(false); // Track fade-out state for text
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 500); // Track screen width
  const [reverseHowAreYou, setReverseHowAreYou] = useState(false); 
  const [reverseHelloBro, setReverseHelloBro] = useState(false);

  const textArray = ["Sujanraj", "Harish"]; // Array of texts to show
  const displayDurations = [1500, 1500]; // Durations in milliseconds for each text

  // Monitor screen width and update `isSmallScreen` accordingly
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 500);
    };
    
    window.addEventListener('resize', handleResize);
    
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (count < 100) {
        setCount((prevCount) => prevCount + 1);
      } else {
        clearInterval(interval);
        setTimeout(() => {
          setLoadingComplete(true);
          if (onLoadingComplete) {
            onLoadingComplete();
          }
        }, 4800); //NEEDS TO BE CHANGED
      }
    }, duration / 100);

    return () => clearInterval(interval);
  }, [count, duration, onLoadingComplete]);

  useEffect(() => {
    if (helloBroComplete && howAreYouComplete) {
      setShowLottie(true);
      // Trigger reverse animations after 2 seconds
      setTimeout(() => {
        setReverseHelloBro(true);
        setReverseHowAreYou(true);
      }, 3500); // Set delay before the reverse animation starts
    }
  }, [helloBroComplete, howAreYouComplete]);

  useEffect(() => {
    if (howAreYouComplete) {
      const timeout = setTimeout(() => {
        if (currentTextIndex === -1) {
          setCurrentTextIndex(0); // Show the first text after 2 seconds
        } else {
          // Set fade out before moving to the next text
          setFadeOut(true);
          setTimeout(() => {
            setFadeOut(false);
            setCurrentTextIndex((prevIndex) => prevIndex + 1); // Show subsequent texts after respective durations
          }, 1000); // Fade out duration
        }
      }, currentTextIndex === -1 ? 100 : displayDurations[currentTextIndex]); // 2-second delay for the first text, custom durations for others

      // Stop showing text after the last item
      if (currentTextIndex >= textArray.length) {
        clearTimeout(timeout);
      }

      return () => clearTimeout(timeout);
    }
  }, [howAreYouComplete, currentTextIndex]);

  // Function to render the full animation for screens above 500px
  const renderFullAnimation = () => (
    <motion.div
      style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100vh", zIndex: 10 }}
      initial={{ y: 0 }}
      animate={{ y: loadingComplete ? "-100vh" : 0 }}
      transition={{ duration: 10, ease: "backIn" }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        sx={{
          width: "100%",
          height: "100vh",
          backgroundImage: `url(${loading_ani})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundColor: "black",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: { lg:"45%", md:'60%', sm:'55%', xs:'85%' },
            mb: 2,
            flexDirection: "row",
          }}
        >
          {/* Animated Hello Bro text */}
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            animate={reverseHelloBro ? { opacity: 0, y: 30 } : { opacity: 1, y: 0 }}
            transition={{ duration: 1, ease: "easeOut", delay: 0.3 }}
            onAnimationComplete={() => setHelloBroComplete(true)} // Set when "Hello Bro" finishes animating
          >
            <Typography variant="h6" color="white" sx={{ fontSize: { lg:"50px", md:'45px', sm:'30px', xs:'24px' }, fontFamily:'Poppins' }}>
              hey there 
            </Typography>
          </motion.div>
          {showLottie && (
            <Player
              autoplay
              loop
              src="https://lottie.host/1912b0dc-469d-4c32-a6f3-8388b9742f31/I8hdkUiDzI.json" // Lottie JSON animation
              className="lottie_animation"
            />
          )}
          {/* Animated How are you text */}
          <motion.div
            initial={{ opacity: 0, y: -40 }}
            animate={reverseHowAreYou ? { opacity: 0, y: -40 } : { opacity: 1, y: 0 }}
            transition={{ duration: 1, ease: "easeOut", delay: 0.3 }} // Slight delay to sync with "Hello Bro"
            onAnimationComplete={() => setHowAreYouComplete(true)} // Set when "How are you" finishes animating
          >
            <Typography variant="h6" color="white" sx={{ fontSize: { lg:"50px", md:'45px', sm:'30px', xs:'24px' }, fontFamily:'Poppins' }}>
              all good pal
            </Typography>
          </motion.div>
        </Box>

        {/* Box for displaying sequential texts */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            width: { lg:"45%", md:'60%', sm:'55%', xs:'85%' },
            marginTop: { lg:'-2%', md:'-2.5%', sm:'-4%', xs:'-6%' },
            height: '50px', // Set a fixed height for this Box to prevent layout shift
            justifyContent: 'center' // Center the text within the box
          }}
        >
          {currentTextIndex >= 0 && currentTextIndex < textArray.length && (
            <motion.div
              initial={{ opacity: 1 }}
              animate={fadeOut ? { opacity: 0 } : { opacity: 1 }} // Apply fade-out animation
              transition={{ duration: 1 }} // Duration for fade-out
            >
              <Typography 
                sx={{ fontFamily:'Delius', fontWeight:'700', fontSize:{ lg:'34px', md:'30px', sm:'25px', xs:'20px' }, color:'rgb(255, 63, 213)' }} 
                data-aos="fade-in" // Add AOS animation
                data-aos-duration="1000" // Set duration of the animation
                key={currentTextIndex} // Add key for proper rendering
              >
                {textArray[currentTextIndex]}
              </Typography>
            </motion.div>
          )}
        </Box>
      </Box>
    </motion.div>
  );


  const renderPhoneScreen = () => (
    <motion.div
      style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100vh",marginTop:'-10%', zIndex: 10 }}
      initial={{ y: 0 }}
      animate={{ y: loadingComplete ? "-100vh" : 0 }}
      transition={{ duration: 10, ease: "backIn" }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        sx={{
          width: "100%",
          height: "100vh",
          backgroundImage:  `url(${loading_ani})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundColor: "black",
          marginTop:'10%'
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: '100%',
            flexDirection: "column",
          }}
        >
          {/* Hello Bro Text Animation from Right to Left */}
          <Box className='phoneScreenFirst'>
            <motion.div
              initial={{ opacity: 0, x: 100 }} // Start from right
              animate={reverseHelloBro ? { opacity: 0, x: 100 } : { opacity: 1, x: 0 }}// Animate to center
              transition={{ duration: 1, ease: "easeOut", delay: 0.3 }}
              onAnimationComplete={() => setHelloBroComplete(true)}
            >
              <Typography variant="h6" color="white" sx={{ fontSize: '28px', fontFamily: 'Poppins' }}>
                hey there
              </Typography>
            </motion.div>
          </Box>

          {/* Lottie Animation */}
          <Box className='phoneScreenAni'>
            {showLottie && (
              <Player
                autoplay
                loop
                src="https://lottie.host/1912b0dc-469d-4c32-a6f3-8388b9742f31/I8hdkUiDzI.json"
                className="lottie_animation"
              />
            )}
          </Box>

          {/* How Are You Text Animation from Left to Right */}
          <Box className='phoneScreenSecond'>
            <motion.div
              initial={{ opacity: 0, x: -100 }} // Start from left
              animate={reverseHowAreYou ? { opacity: 0, x: -100 } : { opacity: 1, x: 0 }} // Animate to center
              transition={{ duration: 1, ease: "easeOut", delay: 0.3 }}
              onAnimationComplete={() => setHowAreYouComplete(true)}
            >
              <Typography variant="h6" color="white" sx={{ fontSize: '28px', fontFamily: 'Poppins' }}>
                all good pal
              </Typography>
            </motion.div>
          </Box>
      </Box>
    {/* Box for displaying sequential texts */}
    <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            width: { lg:"45%", md:'60%', sm:'55%', xs:'85%' },
            marginTop: { lg:'-2%', md:'-2.5%', sm:'-4%', xs:'-6%' },
            height: '50px', // Set a fixed height for this Box to prevent layout shift
            justifyContent: 'center' // Center the text within the box
          }}
        >
          {currentTextIndex >= 0 && currentTextIndex < textArray.length && (
            <motion.div
              initial={{ opacity: 1 }}
              animate={fadeOut ? { opacity: 0 } : { opacity: 1 }} // Apply fade-out animation
              transition={{ duration: 1 }} // Duration for fade-out
            >
              <Typography 
                sx={{ fontFamily:'Delius', fontSize:{ lg:'34px', md:'30px', sm:'25px', xs:'20px' }, color:'rgb(255, 63, 213)' }} 
                data-aos="fade-in" // Add AOS animation
                data-aos-duration="1000" // Set duration of the animation
                key={currentTextIndex} // Add key for proper rendering
              >
                {textArray[currentTextIndex]}
              </Typography>
            </motion.div>
          )}
        </Box>
  </Box>
</motion.div>

  );  return isSmallScreen ? renderPhoneScreen() : renderFullAnimation();
}

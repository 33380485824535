import React, { useEffect } from "react";
import Accordion from "./Accordion";
import { Box, Typography } from "@mui/material";
import AOS from "aos";
import 'aos/dist/aos.css';

const FAQ = () => {
  // Initialize AOS when the component is mounted
  useEffect(() => {
    AOS.init({ once: false }); // Ensures AOS animations trigger each time
  }, []);

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "black",
        color: "white",
        padding: "0px",
        borderRadius: "0px",
        display: { xs: "block", sm: "block", md: "flex", lg: "flex" }, // Block on small devices, flex on medium and larger
        marginTop: { xs: "0", sm: "0", md: "20vh", lg: "15vh" },
      }}
    >
      {/* Left Section for FAQ Title */}
      <Box sx={{ 
        width: { xs: "95%", sm: "100%", md: "40%", lg: "30%" }, // Full width on small, and narrower on larger screens
        textAlign: { xs: "center", sm: "center", md: "left", lg: "left" }, // Centered text on small devices
        padding: { xs: "16px", sm: "16px", md: "24px", lg: "24px" }, // Padding adjustments for better spacing
      }}>
        <Typography
          variant="h4"
          sx={{
            fontWeight: "500",
            marginBottom: "24px",
            fontFamily: "'Mona Sans', sans-serif",
            fontSize: { xs: "36px", sm: "36px", md: "45px", lg: "50px" },
            marginLeft: { xs: "0", sm: "0", md: "10%", lg: "45%" }, // Adjusted margins for small and larger screens
            marginTop: { xs: "0px", sm: "16px", md: "5%", lg: "2%" },
          }}
        >
          <div data-aos="fade-in" data-aos-duration="800">
            FREQUENTLY ASKED QUESTIONS
          </div>
        </Typography>
      </Box>

      {/* Right Section for Accordion */}
      <Box
        sx={{
          width: { xs: "90%", sm: "90%", md: "50%", lg: "40%" }, // Full width on small, and 65% on larger screens
          marginLeft: { xs: "2%", sm: "5%", md: "0", lg: "10%" }, // Margin left on large screens
          padding: { xs: "16px", sm: "16px", md: "24px", lg: "24px" },
          justifyContent:"space-evenly", // Adjust padding for better spacing
          // fontSize: { xs: "26px", sm: "36px", md: "45px", lg: "50px" },
        }}
      >
        <div data-aos="fade-down" data-aos-duration="1000">
          <Accordion
            title="What development methodology does your organization follow?"
            answer="We implement industry-standard Agile frameworks with structured sprint cycles, ensuring iterative development and continuous deployment practices."
          />
          <Accordion
            title="Does your firm provide maintenance/updation services?"
            answer="We deliver comprehensive SLA-based maintenance packages including production support, performance optimization, and scheduled system upgrades."
          />
          <Accordion
            title="How do you handle project pricing?"
            answer="We offer both fixed-price and time-material models, providing transparent cost breakdowns based on project scope and requirements.
                    Simply we will fall in love with your product."
          />
          <Accordion
            title="How do you manage scalability requirements?"
            answer="We architect solutions using microservices, containerization, and cloud-native technologies, ensuring seamless vertical and horizontal scaling capabilities."
          />
          <Accordion
            title="What is your client communication protocol?"
            answer="Our Product Manager is your main contact, providing daily updates, coordinating bi-weekly sprint reviews, and ensuring transparent project tracking via Jira, with regular stakeholder meetings to align with your goals."
          />
          {/* <Accordion
            title="Can I see examples of your work?"
            answer="Yes, check out our portfolio on the website."
          /> */}
        </div>
      </Box>
    </Box>
  );
};

export default FAQ;

import React, { useState, useEffect } from "react";
import { IconButton, Collapse, Typography, Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import AOS from 'aos';
import 'aos/dist/aos.css';

const Accordion = ({ title, answer }) => {
  const [accordionOpen, setAccordionOpen] = useState(false);

  useEffect(() => {
    AOS.init({ once: false });
    if (accordionOpen) {
      AOS.refresh(); // Ensure AOS re-checks after collapse
    }
  }, [accordionOpen]);

  return (
    <Box sx={{ borderBottom: "1px solid #333", padding: "16px 0" }}>
      <Box
        onClick={() => setAccordionOpen(!accordionOpen)}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          cursor: "pointer",
          transition: "color 0.3s ease",
          color: accordionOpen ? "white" : "#ada9a0",
          '&:hover': {
            color: "white",
          },
        }}
      >
        <Typography
          sx={{
            fontWeight: "100",
            fontFamily: "'Mona Sans', sans-serif",
            color: "inherit",
            transition: "color 0.2s ease",
            fontSize:{ xs: "20px", sm: "25px", md: "24px", lg: "28px" }
          }}
        >
          {title}
        </Typography>
        <IconButton
          sx={{
            color: "inherit",
            transition: "color 0.3s ease",
          }}
          aria-label="expand"
        >
          {accordionOpen ? (
            <RemoveIcon sx={{ fontSize: "45px", strokeWidth: "light" }} />
          ) : (
            <AddIcon sx={{ fontSize: "45px", strokeWidth: "light" }} />
          )}
        </IconButton>
      </Box>

      <Collapse in={accordionOpen}>
        <Typography
          sx={{
            color: "#ada9a0",
            marginTop: "8px",
            paddingLeft: "16px",
            fontFamily: "'Mona Sans', sans-serif",
            fontSize:{ xs: "18px", sm: "20px", md: "18px", lg: "20px" },
            textAlign:"left",
            justifyItems:"center",
            width:{ xs: "70%", sm: "90%", md: "90%", lg: "70%" }
          }}
          
        >
          {answer}
        </Typography>
      </Collapse>
    </Box>
  );
};

export default Accordion;
